@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 8px;
  margin-left: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1bb;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #5c5c5c;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}