.otp-container {
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;
}

.otp-input {
  width: 2.25rem !important;
  height: 3rem !important;
  background-color: rgba(249, 249, 249);
  border: 1.1px solid rgba(224, 224, 224);
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-family: "Dosis", "sans-serif";
  transition: border-color 0.2s ease;
}

.otp-input:focus {
  z-index: 10;
  box-shadow: 0 0 0 2px #5FACD9;
  outline: 1px solid #5FACD9;
  outline-offset: 0.5px;
}

body {
  background-color: #82d7f0;
}
